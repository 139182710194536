<template>

<!-- Navbar -->
<div class="w3-top">
  <div class="w3-bar w3-red w3-card w3-left-align w3-large">
    <img class="logo" src="/3.jpg" alt="Mando Proof Logo">
  </div>
</div>

<!-- Header -->
<header class="w3-container w3-red w3-center" style="padding:128px 16px">
  <h1 class="w3-margin w3-xxxlarge">Proofreading for Chinese Speakers <br/>英文校对服务</h1>
</header>

<!-- First Grid -->
<div class="w3-row-padding w3-padding-64 w3-container">
  <div class="w3-content">
    <div class="w3-twothird">
      <h1>Our Services</h1>
      <h5 class="w3-padding-32">We provide English proofreading services for academics, businesses, and individuals. We specialise in: <br/>我们对学者，商务及个人提供如下英语编辑校对服务:</h5>
      <p class="w3-text-grey">• Dissertations, essays, and presentations <br/>论文，作文 和演讲</p>
      <p class="w3-text-grey">• Business documents, website, and video content <br/>商务文件,网站和视频文字</p>
      <p class="w3-text-grey">• School and job applications, and CV writing <br/>学校和工作申请和个人简历</p>
    </div>

    <div class="w3-third w3-center">
      <img class="imgs" src="/2.jpg" alt="Young Professionals">
    </div>
  </div>
</div>

<!-- Second Grid -->
<div class="w3-row-padding w3-light-grey w3-padding-64 w3-container">
  <div class="w3-content">
    <div class="w3-third w3-center">
      <i class="fa fa-shopping-cart w3-padding-64 w3-text-red w3-margin-right"></i>
    </div>

    <div class="w3-twothird">
      <h1>How to Order</h1>
      <h5 class="w3-padding-32"> The order process is quick and simple, it only takes two steps: <br/> 只需如下两个步骤：</h5>

      <p class="w3-text-grey">1. Choose the type of text below and place your order. <br/>请选择需校对或编辑文本的类型，然后根据字数点击 按钮。</p>
      <p class="w3-text-grey">2. Send us your documents <a href = "mailto: mandoproof@gmail.com"> here</a>.<br/>请点击<a href = "mailto: mandoproof@gmail.com">这里</a> 发送你需要校对或编辑的文件。</p>
      <p class="w3-text-grey">After you place your order, we will begin work on your document and email you back the completed version. 
        This usually takes 1-2 days from the date of the order confirmation email. (This is subject to the number of the words in the document.) <br/> 您下订单后，我们将开始校对或编辑您的文档并将完成的文档通过电子邮件发送给您。 此服务通常在客户收到确认订单的电 子邮件后的1-2天内进行，但以文件字数为准。</p>
    </div>
  </div>
</div>

<!-- Third Grid -->
<div class="w3-row-padding w3-padding-64 w3-container">
  <div class="w3-content">
    <div class="w3-twothird">
      <h1>Pricing:</h1>
      <div class="flex-container">
  <div class="w3-text-grey flex-item"><p><b>Very Short Text<br/>非常短的文字</b></p><p>(<i>up to 250 words</i>)<br/>（<i>最多 250 个字</i>）</p><p><button onclick="window.open('https://www.paypal.com/paypalme/mochicoltd/10')">£10</button></p></div>
  <div  class="w3-text-grey flex-item"><p><b>Short Text<br/>短的文字</b></p><p>(<i>up to 500 words</i>)<br/>（<i>最多 500 个字</i>）</p><p><button onclick="window.open('https://www.paypal.com/paypalme/mochicoltd/25')">£25</button></p></div>
  <div class="w3-text-grey flex-item"><p><b>Medium Text<br/>中等的文字</b></p><p>(<i>up to 1000 words</i>)<br/>（<i>最多 1000 个字</i>）</p><p><button onclick="window.open('https://www.paypal.com/paypalme/mochicoltd/50')">£50</button></p></div>
  <div class="w3-text-grey flex-item"><p><b>Long Text<br/>很长的文字</b></p><p>(<i>up to 2000 words</i>)<br/>（<i>最多 1000 个字</i>）</p><p><button onclick="window.open('https://www.paypal.com/paypalme/mochicoltd/100')">£100</button></p></div> 
</div>
    </div>
    <div class="w3-third w3-center">
      <img class="imgs" src="/1.jpg" alt="Young Professionals">

    </div>
  </div>
</div>

<div class="w3-container w3-black w3-center w3-opacity w3-padding-64">
    <h1 class="w3-margin w3-small"> Please send your text in English. 
      The text should not be directly translated from Google Translate or any other AI Software. Please send your text in Microsoft word format.<br/>文本不应直接从谷歌翻译或任何其他人工智能软件翻译。 请将您的文本以微软word格式发送。</h1>
</div>

<!-- Footer -->
<footer class="w3-container w3-padding-64 w3-center w3-opacity">  
  <div class="w3-xlarge w3-padding-32">
    <i class="fa fa-instagram w3-hover-opacity"></i>
 </div>
 <img class="logo" src="/3.jpg" alt="Mando Proof Logo">
 <p>Powered by Mochico Solutions Ltd.</p>
 <GDialog 
 v-model="dialogState"
 scrollable="true">
 <div>
 <strong><span style="font-size: 26px;">PRIVACY NOTICE</span></strong> 
 <br><strong><span style="font-size: 15px;">Last updated October 17, 2022</span></strong>
 <br><br>This privacy notice for Mochico Solutions Ltd ('Company', 'we', 'us', or 'our',),
describes how and why we might collect, store, use, and/or share ('process') your
information when you use our services ('Services'), such as when you:
<br>  • Visit our website at https://mandoproof.com/, or any website of ours that links
to this privacy notice
<br>  • Engage with us in other related ways, including any sales, marketing, or
events
<br><strong>Questions or concerns?</strong> Reading this privacy notice will help you understand your
privacy rights and choices. If you do not agree with our policies and practices, please
do not use our Services. If you still have any questions or concerns, please contact
us at mandoproof@gmail.com.
<br><br><strong><span style="font-size: 15px;">SUMMARY OF KEY POINTS</span></strong>
<br><strong>What personal information do we process?</strong> When you visit, use, or navigate our
Services, we may process personal information depending on how you interact with
Mochico Solutions Ltd and the Services, the choices you make, and the products and
features you use. 
<br><strong>Do we process any sensitive personal information?</strong> We do not process sensitive
personal information.
<br><strong>Do we receive any information from third parties?</strong> We do not receive any
information from third parties.
<br><strong>How do we process your information?</strong> We process your information to provide,
improve, and administer our Services, communicate with you, for security and fraud
prevention, and to comply with law. We may also process your information for other
purposes with your consent. We process your information only when we have a valid
legal reason to do so. 
<br><strong>In what situations and with which parties do we share personal information?</strong> We may share information in specific situations and with specific third parties.
<br><strong>What are your rights?</strong> Depending on where you are located geographically, the
applicable privacy law may mean you have certain rights regarding your personal
information. 
<br><strong>How do you exercise your rights?</strong> The easiest way to exercise your rights is by contacting us. We will
consider and act upon any request in accordance with applicable data protection
laws.

<br><br><strong><span style="font-size: 15px;">1. WHAT INFORMATION DO WE COLLECT?</span></strong>
<br><strong>Personal information you disclose to us</strong>
<br>In Short: We collect personal information that you provide to us.
<br>We collect personal information that you voluntarily provide to us when you express
an interest in obtaining information about us or our products and Services, when you
participate in activities on the Services, or otherwise when you contact us.
<br>Sensitive Information. We do not process sensitive information.
<br>Payment Data. We may collect data necessary to process your payment if you make
purchases, such as your payment instrument number, and the security code
associated with your payment instrument. All payment data is stored by Paypal. You
may find their privacy notice link(s) here:
<a href="https://www.paypal.com/uk/webapps/mpp/ua/privacy-full">https://www.paypal.com/uk/webapps/mpp/ua/privacy-full.</a>
<br>All personal information that you provide to us must be true, complete, and accurate,
and you must notify us of any changes to such personal information.
<br><br><strong><span style="font-size: 15px;"></span>2. HOW DO WE PROCESS YOUR INFORMATION?</strong>
<br>In Short: We process your information to provide, improve, and administer our
Services, communicate with you, for security and fraud prevention, and to comply
with law. We may also process your information for other purposes with your consent.
We process your personal information for a variety of reasons, depending on
how you interact with our Services, including:
<br> • To save or protect an individual's vital interest. We may process your
information when necessary to save or protect an individual’s vital interest,
such as to prevent harm.
<br><br><strong><span style="font-size: 15px;"></span>3. WHAT LEGAL BASES DO WE RELY ON TO PROCESS YOUR INFORMATION?</strong>
<br>In Short: We only process your personal information when we believe it is necessary
and we have a valid legal reason (i.e. legal basis) to do so under applicable law, like
with your consent, to comply with laws, to provide you with services to enter into or
fulfil our contractual obligations, to protect your rights, or to fulfil our legitimate
business interests.
<br>The General Data Protection Regulation (GDPR) and UK GDPR require us to explain
the valid legal bases we rely on in order to process your personal information. As
such, we may rely on the following legal bases to process your personal information:
<br> • Consent. We may process your information if you have given us permission
(i.e. consent) to use your personal information for a specific purpose. You can
withdraw your consent at any time.
<br> • Legal Obligations. We may process your information where we believe it is
necessary for compliance with our legal obligations, such as to cooperate with
a law enforcement body or regulatory agency, exercise or defend our legal
rights, or disclose your information as evidence in litigation in which we are
involved.
<br> • Vital Interests. We may process your information where we believe it is
necessary to protect your vital interests or the vital interests of a third party,
such as situations involving potential threats to the safety of any person.
<br><br><strong><span style="font-size: 15px;">4. WHEN AND WITH WHOM DO WE SHARE YOUR
PERSONAL INFORMATION?</span></strong>
<br>In Short: We may share information in specific situations described in this section
and/or with the following third parties.
<br>We may need to share your personal information in the following situations:
<br> • Business Transfers. We may share or transfer your information in connection
with, or during negotiations of, any merger, sale of company assets, financing,
or acquisition of all or a portion of our business to another company.
<br><br><strong><span style="font-size: 15px;">5. HOW LONG DO WE KEEP YOUR INFORMATION?</span></strong>
<br>In Short: We keep your information for as long as necessary to fulfil the purposes
outlined in this privacy notice unless otherwise required by law.
<br>We will only keep your personal information for as long as it is necessary for the
purposes set out in this privacy notice, unless a longer retention period is required or
permitted by law (such as tax, accounting, or other legal requirements).
<br>When we have no ongoing legitimate business need to process your personal
information, we will either delete or anonymise such information, or, if this is not
possible (for example, because your personal information has been stored in backup
archives), then we will securely store your personal information and isolate it from
any further processing until deletion is possible.
<br><br><strong><span style="font-size: 15px;">6. DO WE COLLECT INFORMATION FROM MINORS?</span></strong>
<br>In Short: We do not knowingly collect data from or market to children under 18 years
of age.
<br>We do not knowingly solicit data from or market to children under 18 years of age. By
using the Services, you represent that you are at least 18 or that you are the parent
or guardian of such a minor and consent to such minor dependent’s use of the
Services. If we learn that personal information from users less than 18 years of age
has been collected, we will take reasonable measures to
promptly delete such data from our records. If you become aware of any data we
may have collected from children under age 18, please contact us at
mandoproof@gmail.com.
<br><br><strong><span style="font-size: 15px;">7. WHAT ARE YOUR PRIVACY RIGHTS?</span></strong>
<br>In Short: In some regions, such as the European Economic Area (EEA) and United
Kingdom (UK), you have rights that allow you greater access to and control over your
personal information. You may review, change, or erase your data at any
time.
<br>In some regions (like the EEA and UK), you have certain rights under applicable data
protection laws. These may include the right (i) to request access and obtain a copy
of your personal information, (ii) to request rectification or erasure; (iii) to restrict the
processing of your personal information; and (iv) if applicable, to data portability. In
certain circumstances, you may also have the right to object to the processing of your
personal information. You can make such a request by contacting us by using the
contact details provided in the section 'HOW CAN YOU CONTACT US ABOUT THIS
NOTICE?' below.
<br>We will consider and act upon any request in accordance with applicable data
protection laws.
<br>If you are located in the EEA or UK and you believe we are unlawfully processing
your personal information, you also have the right to complain to your local data
protection supervisory authority. You can find their contact details here: <a href="https://ec.europa.eu/justice/data-protection/bodies/authorities/index_en.htm">https://ec.europa.eu/justice/data-protection/bodies/authorities/index_en.htm.</a>
<br>If you are located in Switzerland, the contact details for the data protection authorities
are available here: <a href="https://www.edoeb.admin.ch/edoeb/en/home.html">https://www.edoeb.admin.ch/edoeb/en/home.html.</a>
<br>Withdrawing your consent: If we are relying on your consent to process your
personal information, you have the right to withdraw your consent at any time. You
can withdraw your consent at any time by contacting us by using the contact details
provided in the section 'HOW CAN YOU CONTACT US ABOUT THIS NOTICE?'
below.
<br>However, please note that this will not affect the lawfulness of the processing before
its withdrawal nor, will it affect the processing of your personal information conducted
in reliance on lawful processing grounds other than consent.
<br>If you have questions or comments about your privacy rights, you may email us at
mandoproof@gmail.com.
<br><br><strong><span style="font-size: 15px;">8. CONTROLS FOR DO-NOT-TRACK FEATURES</span></strong>
<br>Most web browsers and some mobile operating systems and mobile applications
include a Do-Not-Track ('DNT') feature or setting you can activate to signal your
privacy preference not to have data about your online browsing activities monitored
and collected. At this stage no uniform technology standard for recognising and
implementing DNT signals has been finalised. As such, we do not currently respond
to DNT browser signals or any other mechanism that automatically communicates
your choice not to be tracked online. If a standard for online tracking is adopted that
we must follow in the future, we will inform you about that practice in a revised
version of this privacy notice.
<br><br><strong><span style="font-size: 15px;">9. DO CALIFORNIA RESIDENTS HAVE SPECIFIC PRIVACY
RIGHTS?</span></strong>
<br>In Short: Yes, if you are a resident of California, you are granted specific rights
regarding access to your personal information.
<br>California Civil Code Section 1798.83, also known as the 'Shine The Light' law,
permits our users who are California residents to request and obtain from us, once a
year and free of charge, information about categories of personal information (if any)
we disclosed to third parties for direct marketing purposes and the names and
addresses of all third parties with which we shared personal information in the
immediately preceding calendar year. If you are a California resident and would like
to make such a request, please submit your request in writing to us using the contact
information provided below.
<br>If you are under 18 years of age, reside in California, and have a registered account
with Services, you have the right to request removal of unwanted data that you
publicly post on the Services. To request removal of such data, please contact us
using the contact information provided below and include the email address
associated with your account and a statement that you reside in California. We will
make sure the data is not publicly displayed on the Services, but please be aware
that the data may not be completely or comprehensively removed from all our
systems (e.g. backups, etc.).
<br><br><strong><span style="font-size: 15px;">10. DO WE MAKE UPDATES TO THIS NOTICE?</span></strong>
<br>In Short: Yes, we will update this notice as necessary to stay compliant with relevant
laws.
<br>We may update this privacy notice from time to time. The updated version will be
indicated by an updated 'Revised' date and the updated version will be effective as
soon as it is accessible. If we make material changes to this privacy notice, we may
notify you either by prominently posting a notice of such changes or by directly
sending you a notification. We encourage you to review this privacy notice frequently
to be informed of how we are protecting your information.
<br><br><strong><span style="font-size: 15px;">11. HOW CAN YOU CONTACT US ABOUT THIS NOTICE?</span></strong>
<br>If you have questions or comments about this notice, you may email us at
mandoproof@gmail.com
<br><br><strong><span style="font-size: 15px;">12. HOW CAN YOU REVIEW, UPDATE, OR DELETE THE
DATA WE COLLECT FROM YOU?</span></strong>
<br>Based on the applicable laws of your country, you may have the right to request
access to the personal information we collect from you, change that information, or
delete it. To request to review, update, or delete your personal information, please
submit a request by emailing us at mandoproof@gmail.com.
</div>
  </GDialog>

  <u @click="dialogState = true">
    Privacy Policy
  </u>
</footer>
</template>

<script>
export default {
      name: 'mainPage',
      data () {
        return {
          dialogState: false
        }
      }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
body,h1,h2,h3,h4,h5,h6 {font-family: "Lato", sans-serif}
.w3-bar,h1,button {font-family: "Montserrat", sans-serif}
.fa-anchor,.fa-shopping-cart {font-size:200px}
.imgs {
  width:500px;
}
.flex-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 1.5em;
  text-align: center;
}
.flex-item {
    border: none;
    border-right: 2px solid black;
    text-align: center;
    margin: 0 5px;
    padding-right: 15px;
  }
  .flex-item:last-child {
      border: none;
  }
  .logo {
  width:100px;
}
</style>